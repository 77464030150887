import 'slick.min.js'
import 'jquery.countTo.js'
import 'jquery.maskedinput.js'
import 'jquery.validate.js'

import '@chenfengyuan/datepicker/dist/datepicker';
import '@chenfengyuan/datepicker/i18n/datepicker.ru-RU';

import url from 'url';

const queryParser = require('query-string');

$(document).ready(function () {
  // Если есть селектор id главной страницы, то проводим обработку урла
  if ($('#home_page').length > 0) {
    // Получаем текущий урл
    const parsedUrl = url.parse(window.location.href)
    // Парсим query-параметры из урла
    const parsedQuery = queryParser.parse(parsedUrl.query)
    
    // Если query-параметры есть и среди них есть параметры hash и id,
    // то пробуем отправить запрос на подтверждение учетной записи
    if (Object.keys(parsedQuery).length > 0 && parsedQuery.hash && parsedQuery.id) {
      $.ajax({
        url: 'registration_requests/' + parsedQuery.id + '/confirm/' + parsedQuery.hash,
        type: 'PATCH',
        data: {}
      });
    }
  }

  $('[data-toggle="datepicker"]').datepicker({ language: 'ru-RU' });

	// header fixed
	$(window).scroll( function(){
		if ($(this).scrollTop() > 50){
			$('.header').addClass('fixed');
		}else{
			$('.header').removeClass('fixed');
		}
	});

	// mob nav
	$(document).on('click','.mob-nav-icon',function(e){
		e.preventDefault();
		if($(this).hasClass('active')){
			$('.header-nav').removeClass('vis');
			$('.mob-nav-icon').removeClass('active');
		}else{
			$('.header-nav').addClass('vis');
			$('.mob-nav-icon').addClass('active');
		}
	});

	// scroll
	$('.scroll').click(function(e){
		e.preventDefault();
		var selected = $(this).attr('href').replace('/', '');
		$.scrollTo(selected, 1000, {offset: -70});
		$('.header-nav').removeClass('vis');
		$('.mob-nav-icon').removeClass('active');
		return false;
	});

	// mask for phone
	$("input[name='registration_request[phone]']").mask("+7 (999) 999 99 99");
	$("input[name='registration_request[vista_card_number]']").mask("9999 99999 99999");
	// form
	$('form').each(function(){
		$(this).validate({
			rules: {
				'registration_request[phone]': {
					required: true,
					minlength: 6,
				},
				'registration_request[name]': {
					required: true,
					minlength: 2,
				},
				'registration_request[date_of_birth]': {
					required: true,
					minlength: 6,
                    regex: "^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$"
				},
				'registration_request[email]': {
					required: true,
          email: true
				}
			}
		});
	});
	$.validator.messages.required = 'Обязательно для заполнения';
	$.validator.messages.email = 'Введите валидный e-mail адрес';
    $.validator.addMethod(
            "regex",
            function(value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            },
            "Проверьте корректность данных."
    );

	// info-hidden
	$(document).on('click','.info-trigger a',function(e){
		e.preventDefault();
		if($(this).hasClass('active')){
			$('.info-hidden').slideUp();
			$(this).removeClass('active');
		}else{
			$('.info-hidden').slideDown();
			$(this).addClass('active');
			if($(window).width() < 1023){
				$.scrollTo('.info-hidden', 1000, {offset: -70});
			}
		}
	});

	// count numbers
	var document_top = $(document).scrollTop();
	var height = $(window).height();
	var offset2 = $('.info').offset().top - height + height/6;
	if(document_top > offset2){
		$('.start').countTo();
		$('.info .count').removeClass('start');
	}
	var offset = $('.numbers-block').offset().top - height + height/6;
	if(document_top > offset){
		$('.start').countTo();
		$('.numbers-block .count').removeClass('start');
	}
	$(window).scroll( function(e){
		var document_top = $(document).scrollTop();
		var height = $(window).height();
		var offset = $('.numbers-block').offset().top - height + height/6;
		if(document_top > offset){
			$('.start').countTo();
			$('.numbers-block .count').removeClass('start');
		}
	});

	// animation trigger
	var document_top = $(document).scrollTop();
	var height = $(window).height();
	$('.animatable').each(function(){
		var offset = $(this).offset().top - height + height/6;
		if(document_top > offset){
			$(this).addClass('animated');
		}else{
			$(this).removeClass('animated');
		}
	});
	$(window).scroll( function(e){
		var document_top = $(document).scrollTop();
		var height = $(window).height();
		$('.animatable').each(function(){
			var offset = $(this).offset().top - height + height/6;
			if(document_top > offset){
				$(this).addClass('animated');
			}else{
				$(this).removeClass('animated');
			}
		});

	});

    $('#subscribe-form').submit(function(e){
        if($(this).valid()){

            e.preventDefault();

            $.ajax({
                url: '/subscribe',
                type: 'POST',
                data: {email: $('.subscribe-input').val()},
                success: function(data){

                    if(data.error) {
                        $.fancybox.open( $('#error-popup'), {
                           touch: false
                        });

                    } else {
                        $.fancybox.open( $('#info-popup'), {
                           touch: false
                        });
                    }

                    console.log(data.error);
                },
                fail: function(){
                    console.log(data);
                    $.fancybox.open( $('#error-popup'), {
                       touch: false
                    });
                }
            });

        }

        return false;
    });


    $('#register-form').submit(function(e){
        if($(this).valid()){

            e.preventDefault();
            var el = $(this);
            $.ajax({
                url: '/register',
                type: 'POST',
                data: $(el).serialize(),
                success: function(data){
                    if(data.error) {
						grecaptcha.reset();

						if(data.message){
							$('#error-popup .popup-text').html(data.message);
						} else {
							$('#error-popup .popup-text').html('Произошла ошибка. Пожалуйста, проверьте корректность введенных данных')
						}

                        $.fancybox.open( $('#error-popup'), {
                           touch: false
                        });

                    } else {
                        $.fancybox.open( $('#info-popup'), {
                           touch: false
                        });
                    }

                    console.log(data.error);
                },
                fail: function(){
                    console.log(data);
                    $.fancybox.open( $('#error-popup'), {
                       touch: false
                    });
                }
            });

        }

        return false;
    });

    if(location.hash == '#confirm-success'){
        $.fancybox.open( $('#confirm-success'), {
           touch: false
        });
    }

    if(location.hash == '#confirm-error'){
        $.fancybox.open( $('#confirm-error'), {
           touch: false
        });
    }

    if(location.hash == '#subscribe-success'){
        $.fancybox.open( $('#info-popup'), {
           touch: false
        });
    }
});
